import { SeoProductCombination } from 'src/pages/Product/Seo'

/* Next */
import Link from 'next/link'

/* Redux */
import { useSelector } from 'src/redux/hooks'

/* Material */
import Typo from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import useStyles from './styles'

/* Types */
import { tProductPreview } from 'src/shared/frontendTypes/product'

/* Components */
import PriceDisplay from 'src/components/PriceDisplay'
import DeliverytimeDisplay from './DeliverytimeDisplay'

/* Functions */
import productUrl from 'src/functions/productUrl'

/* Custom */
import PreviewImage from './PreviewImage'

export type display =
  | 'title'
  | 'center'
  | 'attributes'
  | 'price'
  | 'deliverytime'
  | 'no-card'

interface Props {
  product: tProductPreview
  display?: display[]
}

const Product: React.FC<Props> = ({ 
  product: {
    id, name, metaTitle, path, price, discount, images, options, attributes,
    deliverytime, stock, onlyStock,

    brandTitel, ean, sku,
  },
  display,
}) => {

  const classes = useStyles()
  const touchDevice = useSelector(store => store.App.isTouchDevice)

  const url = productUrl(id,path,options)

  const d = {
    title:        display ? display.includes('title')        : true,
    price:        display ? display.includes('price')        : true,
    deliverytime: display ? display.includes('deliverytime') : true,
    attributes:   display ? display.includes('attributes')   : true,
    center:       display ? display.includes('center')       : false,
    noCard:       display ? display.includes('no-card')      : false,
  }

  const bgCard = display?.find(d => d.startsWith('bg-card-'))?.split('-')[2] || '#ffffff';

  const productCard = <Card 
    className={[
      classes.card,
      !touchDevice ? 'hover' : null,
      d.noCard ? classes.noShaddow : null,
    ].join(' ')}
    style={{ backgroundColor: bgCard }}
  >
    
    <PreviewImage 
      images={images}
      productID={id}
      metaTitle={metaTitle}
    />

    {( d.title || d.price || d.deliverytime ) &&
      <CardContent className={classes.cardContent}>
        
        {d.title &&
          <div className='card-product-title'>

            <Typo align={d.center ? 'center' : 'left'}>
              {metaTitle}
            </Typo>

            {d.attributes &&
              <ul>
                {attributes.map((attribute,i)=>(
                  <li key={i} >
                    {attribute}
                  </li>
                ))}
              </ul>
            }

          </div>
        }
        
        {( d.price || d.deliverytime ) &&
          <div>
            {d.price &&
              <PriceDisplay 
                price={discount 
                  ? {
                    regularPrice:  price,
                    discountPrice: discount.price,
                    title:         discount.metaTitle,
                    type:          discount.type,
                    dateEnd:       discount.dateEnd,
                  } 
                  : price
                }
                component='p' variant='body1' align='right'
                className={classes.price}
              />
            }

            {d.deliverytime &&
              <DeliverytimeDisplay
                {...{
                  deliverytime,
                  stock,
                  onlyStock
                }}
              />
            }
          </div>
        }

      </CardContent>
    }
    
  </Card>

  return <section className={classes.cardColumn}>

    <SeoProductCombination {...{
      id, path, name,
      description: metaTitle,
      brandTitel,

      images: images.map(i=>i.xxl),
      
      attributes: attributes.join(', '),
      options,
      price, 
      ean, sku,

      onlyStock,
      stock,
    }} />

    <Link href={url} legacyBehavior>
      <a>{productCard}</a>
    </Link>
  </section>
}

export default Product
