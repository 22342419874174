/* Material */
import Typo from '@mui/material/Typography'

import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { red } from '@mui/material/colors'

/* Functions */
import formatEuro from 'src/functions/formatEuro'
import formatPercent from 'src/functions/formatPercent'

/* Types */
import { discountPrice } from 'src/shared/frontendTypes/product'
import { TypographyProps } from '@mui/material'

interface Props extends TypographyProps {
  price: number | discountPrice
  qty?: number
  className?: string
  component: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    discountPriceWrapper: {
      display: 'grid',
      gridTemplateAreas: `
        "p s s"
        "p d d"
      `,
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '1rem',
    },

    strikePrice: {
      gridArea: 's',
      //color: red[500],
      fontSize: 'small',
      textDecoration: 'line-through',
    },

    discountPrice: {
      gridArea: 'd',
      color: red[500],
    },

    price: {
      gridArea: 'd',
    },

    percent: {
      gridArea: 'p',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      padding: theme.spacing(0.5, 1),
    },
  })
)

const PriceDisplay: React.FC<Props> = ({ price, qty = 1, className, ...rest }) => {
  const classes = useStyles()

  /* ++++++++++++++++++++++++++++++++++++++++++++++++++ */
  // Normaler Preis
  if (typeof price === 'number')
    return (
      <div className={className}>
        <Typo {...rest}>
          {formatEuro(price * qty)} *¹
        </Typo>
      </div>
    )

  /* ++++++++++++++++++++++++++++++++++++++++++++++++++ */
  // Falls der Discount "invisible" ist,
  // dann den Preis wie bei einem normalen Preis anzeigen (ohne Streichpreis/Prozent)
  if (price.type === 'invisible')
    return (
      <div className={className}>
        <Typo {...rest}>
          {formatEuro(price.discountPrice * qty)} *¹
        </Typo>
      </div>
    )

  /* ++++++++++++++++++++++++++++++++++++++++++++++++++ */
  // Preis einer Rabatt-Aktion
  return (
    <div className={classes.discountPriceWrapper + ' ' + className}>
      <Typo {...rest} className={classes.strikePrice}>
        {formatEuro(price.regularPrice * qty)} *¹
      </Typo>

      <Typo {...rest} className={classes.discountPrice}>
        {formatEuro(price.discountPrice * qty)} *¹
      </Typo>

      <Typo {...rest} className={classes.percent}>
        {formatPercent(
          (price.regularPrice - price.discountPrice) / price.regularPrice * -1
        )}
      </Typo>
    </div>
  )
}

export default PriceDisplay